import React from "react";

export default function AboutUs() {
    return (
      <>
          <h2 className='text-slate-700 text-4xl md:text-5xl font-black mt-20'>About us</h2>
          <h6 className='mt-4 md:text-xl text-slate-500'>会社概要</h6>
          <div className='gap-14 md:gap-20 mt-5'>
              <div className="p-4">
                  <ul className=" list-disc pl-6 md:pl-10 space-y-4 text-slate-600 text-sm md:text-base">
                      <li>テコネット株式会社 (teconet Corp.)</li>
                      <li>設立	2019年5月7日</li>
                      <li>資本金	60万円</li>
                      <li>本社所在地	〒530-0027 大阪市北区堂山町3-3 日本生命梅田ビル7F</li>
                      <li>開発所在地	〒530-0057 大阪市北区曾根崎2-7-5 TECHNO-EIGHT BLDG.4F</li>
                      <li>代表者 代表取締役 鏑木 哲</li>
                      <li>従業員数	7名</li>
                      <li>事業内容	スマートフォンアプリ開発、WEBアプリ開発、サーバ・ネットワーク構築・運用</li>
                  </ul>
              </div>
          </div>
      </>
    )
}
